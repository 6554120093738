<template>
  <div class="mt-4">
    <b-row
      v-if="clientSelected"
      id="projectNotesIndex"
      ref="projectNotes"
      style="background-color: #ffffff"
    >
      <b-col md="12">
        <NotesTable :type="'project'" />
      </b-col>
    </b-row>
    <div v-if="!clientSelected" class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <b-alert variant="danger" show>
          {{ $t("general.selectClientFirst") }}
        </b-alert>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import NotesTable from "@/components/Tenants/Notes/Table";

export default {
  name: "ProjectsNotesIndex",
  components: {
    NotesTable
  },
  data() {
    return {
      breadcrumb: [
        {
          title: this.$t("menu.projects"),
          route: ""
        },
        {
          title: this.$t("menu.projectNotes"),
          route: ""
        }
      ]
    };
  },
  computed: {
    clientSelected: function () {
      return this.$store.getters.selectedClient !== null;
    }
  },
  watch: {
    clientSelected: function () {
      alert();
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, this.breadcrumb);
  }
};
</script>
