<template>
  <div id="notesCard" class="card card-custom grid-stack-item-content">
    <CreateNoteSidebar />
    <EditNoteSidebar />
    <div class="card-header" style="cursor: move">
      <div class="card-title">
        <h3 class="card-label">{{ $t("notes.notes") }}</h3>
      </div>
      <div class="card-toolbar">
        <button class="btn btn-primary btn-hover" @click="createNote">
          <i class="fal fa-plus"></i>
          {{ $t("notes.noteCreate") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body column">
      <TableWrapper :meta="meta" @reload-data="loadNotes">
        <b-table
          responsive
          :items="notes"
          :fields="fields"
          class="dataTable table table-head-custom"
          :show-empty="true"
          :empty-text="$t('table.noRecords')"
        >
          <template #cell(actions)="data">
            <b
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="editNote(data.item)"
            >
              <i class="fal fa-pen"></i>
            </b>
            <b
              class="btn btn-icon btn-light btn-sm mr-1"
              @click="deleteNote(data.item)"
            >
              <i class="fal fa-trash"></i>
            </b>
          </template>
        </b-table>
      </TableWrapper>
    </div>
  </div>
</template>

<script>
import Notes from "@/components/Tenants/Notes/notes";
import CreateNoteSidebar from "@/components/Tenants/Notes/Create";
import EditNoteSidebar from "@/components/Tenants/Notes/Edit";
import { formatDate } from "@/components/Tools/modifiers";
import { bus } from "@/main";
import { mapGetters } from "vuex";
import TableWrapper from "@/components/Tools/TableWrapper";

export default {
  name: "TenantsNotesTable",
  components: {
    TableWrapper,
    CreateNoteSidebar,
    EditNoteSidebar
  },
  props: ["notable_type", "notable_id"],
  data() {
    return {
      notes: [],
      filters: {
        notable_type: this.notable_type ?? "",
        notable_id: this.notable_id ?? ""
      },
      searchTitle: "",
      fields: [
        {
          key: "id",
          label: this.$t("table.id"),
          sortable: true,
          thStyle: "width: 70px"
        },
        {
          key: "title",
          label: this.$t("table.title"),
          sortable: true
        },
        {
          key: "notable_type",
          label: this.$t("notes.type"),
          sortable: true
        },
        {
          key: "notable_id",
          label: this.$t("notes.typeId"),
          sortable: true
        },
        {
          key: "created_at",
          formatter: "formatDateAssigned",
          label: this.$t("table.createdAt"),
          sortable: true
        },
        {
          key: "updated_at",
          formatter: "formatDateAssigned",
          label: this.$t("table.updatedAt"),
          sortable: true
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: false,
          thStyle: { width: "170px" }
        }
      ],
      // Meta info
      meta: {},
      isBusy: false
    };
  },
  computed: {
    ...mapGetters(["selectedClient"]),
    ...mapGetters("route", ["requestParams", "cancelToken"])
  },
  watch: {
    selectedClient: function () {
      this.loadNotes();
    }
  },
  mounted() {
    this.loadNotes();
    bus.$on("noteCreated", this.loadNotes);
  },
  destroyed() {
    bus.$off("noteCreated", this.loadNotes);
  },
  methods: {
    loadNotes() {
      if (!this.$store.getters.apiToken && this.appVersion < 2) {
        return;
      }
      if (this.selectedClient === null) {
        this.notes = [];
        return false;
      }
      this.isBusy = true;
      let params = this.requestParams();
      let cancelToken = this.cancelToken();
      Notes.getAll(params, this.filters, cancelToken)
        .then(response => {
          this.notes = response.data.data;
          this.meta = response.data.meta;
          this.isBusy = false;
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = this.axios.isCancel(error);
        });
    },
    formatDateAssigned(value) {
      return formatDate(value, "DD.MM.YYYY");
    },
    createNote() {
      this.$root.$refs.CreateNoteView.note.notable_type =
        this.notable_type ?? "";
      this.$root.$refs.CreateNoteView.note.notable_id = this.notable_id ?? "";
      this.$root.$refs.CreateNoteView.createSidebarVisible = true;
    },
    editNote(note) {
      this.$root.$refs.EditNoteView.note = note;
      this.$root.$refs.EditNoteView.editSidebarVisible = true;
    },
    deleteNote(note) {
      this.$swal
        .fire({
          title: this.$t("notes.deleteNoteQuestion", { title: note.title }),
          icon: "warning",
          showConfirmButton: true,
          confirmButtonText: this.$t("general.delete"),
          showCancelButton: true,
          reverseButtons: true,
          cancelButtonText: this.$t("general.cancel")
        })
        .then(swalResponse => {
          if (swalResponse.isConfirmed) {
            this.isBusy = true;
            Notes.delete(note.id)
              .then(() => {
                this.$toast.fire({
                  icon: "success",
                  title: this.$t("notes.deletedText", { title: note.title })
                });
                this.loadNotes();
              })
              .catch(error => {
                this.$swal.fire({
                  title: this.$t("general.caution"),
                  text: error.response.data.message,
                  icon: "error"
                });
                this.isBusy = false;
              });
          }
        });
    }
  }
};
</script>
